.switch-wrapper {
  display: flex;
  margin-right: 8px;

  &.disabled {
    opacity: 0.15;
    cursor: not-allowed;
  }
  .label {
    color: #fff;
    font-size: 14px;
    font-weight: 900;
    line-height: 100%;
  }

  .MuiGrid-root {
    margin-bottom: 0;
  }

  .MuiSwitch-root {
    border-radius: 12px;
    width: 36px;
    height: 20px;
    padding: 0;

    span.MuiSwitch-track {
      background-color: #546efe;
      opacity: 0.5;
      
    }
  }
  .MuiSwitch-switchBase {
    padding: 2px 0 0 0;
    right: 21px;
    left: 1px;
    .MuiSwitch-thumb {
      width: 16px;
      height: 16px;
      color: var(--color-white);
    }
  }
}
